<button
  mat-icon-button
  (click)="toggleNotifications()"
  class="text-white"
>
  <mat-icon
    [matBadge]="unreadCount()"
    matBadgeColor="warn"
    [matBadgeHidden]="unreadCount() < 1"
  >
    notifications
  </mat-icon>
</button>
