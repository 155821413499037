import { Injectable } from '@angular/core';
import { HotToastService } from '@ngxpert/hot-toast';
import {
  find, head, isUndefined, reduce, set, snakeCase, toUpper
} from 'lodash';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/core/states/app/app.state';
import { FacilityState } from '~core/states/facility/facility.state';
import { STIL_LanguageDataService } from '~shared/services/apiSTILLanguageController';
import { STIL_LanguageTranslationDataService } from '~shared/services/apiSTILLanguageTranslationController';
import { Language } from '~translations/models/language.model';

import { Translation } from '../../models/translation.model';
import { TranslationsState } from '../../state/translations.state';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(
    private appState: AppState,
    private facilityState: FacilityState,
    private languageDataSvc: STIL_LanguageDataService,
    private state: TranslationsState,
    private toast: HotToastService,
    private translationDataSvc: STIL_LanguageTranslationDataService
  ) { }

  async initTranslationsStateAsync(): Promise<any> {
    const facilityId = this.appState.get('facilityId');
    const generalSettings = await this.facilityState.generalSettings$.pipe(first(generalSettings => !isUndefined(generalSettings))).toPromise();
    const languages = await this.languageDataSvc.getRecordsForParentID(+facilityId, true).toPromise();

    if (!generalSettings.defaultLanguage && languages?.length) {
      this.toast.warning(`Default Language is not set! Defaulting to ${languages?.[0]?.name}`);
    }

    if (!languages.length) {
      this.toast.error(`No Languages are configured!`);
    }

    const defaultLanguage = find(languages, { name: generalSettings.defaultLanguage }) ?? head(languages);
    const activeLanguageId = defaultLanguage?.ID;
    const activeLanguage = find(languages, { ID: activeLanguageId });

    this.state.set('languages', languages);
    this.state.set('defaultLanguage', defaultLanguage);
    this.state.set('activeLanguage', activeLanguage);

    this.updateActiveLanguage(activeLanguage);
  }

  async updateActiveLanguage(language: Language): Promise<any> {
    const translationData =  await this.translationDataSvc.getRecordsForParentID(language.ID).toPromise();
    const translations = this.buildTranslationConfig(translationData);

    this.state.set('translations', translations);
  }

  private buildTranslationConfig(translations: Translation[]): any {
    return reduce(translations, (translationsObj, {
      controlName, defaultCaption, formName, itemType, preferredCaption
    }) => {
      let valuePath = [ itemType, defaultCaption ];
      let variant = false;

      if (itemType.toLowerCase().startsWith('new_')) {
        valuePath = [
          itemType,
          formName,
          ...controlName.split('_'),
          defaultCaption
        ];

        if (itemType.includes('::')) {
          // If there is a variant, then split the field, and
          // spread it into the path so it can be sanitized properly
          variant = true;
          valuePath = [ ...itemType.split('::'), ...valuePath.slice(1) ];
        }

      } else if (itemType === 'DEFAULT_CONTROL_VALUE') {
        valuePath = [
          'DEFAULTS',
          formName,
          ...controlName.split('_'),
          defaultCaption
        ];
      } else if (formName !== 'Permit') {
        valuePath.splice(1, 0, formName);
      }

      valuePath = valuePath.map(value => toUpper(snakeCase(value)));

      if (variant) {
        // If there is a variant, rejoin the first two elements with the variant indicator
        valuePath = [ `${valuePath[0]}::${valuePath[1]}`, ...valuePath.slice(2) ];
      }

      if (valuePath[0] === valuePath[1]) {
        valuePath.shift();
      }

      return set(translationsObj, valuePath, preferredCaption);
    }, {});
  }
}
