<button
  mat-button
  [matMenuTriggerFor]="menu"
  id="user-menu-button"
  class="flex"
>
  <div
    class="w-[45px] h-[45px] flex"
    [ngClass]="iconOnly ? 'p-1' : 'p-2'"
    [innerHtml]="svg | domSanitize"
  ></div>

  @if (!iconOnly) {
    <span class="username pr-2 w-[125px]">
      {{ user?.firstName }}
      {{ user?.lastName }}
    </span>
    <i class="fas fa-caret-down m-2"></i>
  }
</button>

<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    [matMenuTriggerFor]="langs"
  >
    <i class="fas fa-language fa-lg mr-2"></i>
    {{ translationBasePath + '.' + 'Language' | translate }}
  </button>

  @if (!isActiveDirectory) {
    <a
      style="text-decoration: none"
      mat-menu-item
      (click)="resetPassword()"
    >
      <i class="fas fa-key fa-lg mr-2"></i>
      {{ translationBasePath + '.' + 'Rest Password' | translate }}
    </a>
  }

  @if (infoUrl?.length) {
    <a
      style="text-decoration: none"
      mat-menu-item
      (click)="goToLink()"
    >
      <i class="fas fa-info fa-lg ml-1 mr-3"></i>
      {{ translationBasePath + '.' + 'Information' | translate }}
    </a>
  }

  @if (isMobile$ | async) {
    <a
      style="text-decoration: none"
      mat-menu-item
      (click)="toggleAdminToolbar()"
    >
      <i class="fa fa-angle-double-left fa-lg ml-1 mr-3"></i>
      {{ translationBasePath + '.' + 'Toggle Admin Bar' | translate }}
    </a>
  }

  <a
    style="text-decoration: none"
    mat-menu-item
    (click)="logout()"
  >
    <i class="fas fa-sign-out-alt fa-lg mr-2"></i>
    {{ translationBasePath + '.' + 'Sign Out' | translate }}
  </a>
</mat-menu>

<mat-menu #langs="matMenu">
  @for (language of languages$ | async; track $index) {
    <button
      mat-menu-item
      (click)="selectLanguage(language)"
    >
      {{ language.name }}
      @if ((activeLanguage$ | async)?.ID === language?.ID) {
        <i class="fas fa-check"></i>
      }
    </button>
  }
</mat-menu>
